import { EditorView, basicSetup } from 'codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { EditorState } from '@codemirror/state';

import Particles from 'particlesjs';
import { dracula } from 'thememirror';
import scrollToElement from 'scroll-to-element';
import gsap from 'gsap';
import { TextPlugin } from 'gsap/all';

// Updates the displayed skill dynamically
const updateIDevelop = () => {
  const skills = [
    'Web apps',
    `APIs`,
    'Dashboards',
    'Apps',
    'Websites',
    'Backends',
    'Architectures',
    'CI/CD pipelines',
  ];

  gsap.to(document.getElementById('tween'), {
    duration: 0.5,
    text: { value: skills[Math.floor(Math.random() * skills.length)] },
    ease: 'none',
  });
};

// Toggles navigation menu state
function toggleNav() {
  const burger = document.getElementById('burger');
  const burgerText = document.getElementById('burger-text');
  const nav = document.getElementById('nav');

  burger.classList.toggle('is-open');
  nav.classList.toggle('is-open');
  burgerText.classList.toggle('is-open');
}

// Main setup after DOM content is loaded
document.addEventListener('DOMContentLoaded', () => {
  const isDesktop = () => window.innerWidth > 1000;

  // Register GSAP plugins
  gsap.registerPlugin(TextPlugin);

  // Update skill display every 2.5 seconds
  setInterval(updateIDevelop, 2500);

  // Scroll-to functionality
  const scrollTo = (elementId) => scrollToElement(elementId);

  // Add event listeners for navigation
  const skillSectionLink = document.getElementById('skills-section-link');
  skillSectionLink.addEventListener('click', () => {
    toggleNav();
    scrollTo('skills-section');
  });

  const contactMeSection = document.getElementById('contact-section-link');
  contactMeSection.addEventListener('click', () => {
    toggleNav();
    scrollTo('contact-section');
  });

  const openNavButton = document.getElementById('open-nav');
  openNavButton.addEventListener('click', toggleNav);

  // Initialize code editor
  const editorRef = document.getElementById('mountme-baby');
  const initialCode = `
    import { WorkExperience, Education } from '@mark/interfaces';

    class MarkVanDerSteenhoven {
      name: string;
      dayOfBirthTimeStamp: number;

      constructor() {
        this.name = 'Mark van der Steenhoven';
        this.dayOfBirthTimeStamp = 703956095;
      }

      workExperience(): Array<WorkExperience> {
        return [
          { company: 'Strangelove', role: 'Freelance backend developer', from: '06/2015', to: '10/2016' },
          { company: 'Ilocate', role: 'Freelance Full-stack developer', from: '10/2016', to: '02/2017' },
          { company: 'DigiMonks', role: 'Full-stack developer', from: '02/2017', to: '02/2019' },
          { company: 'Dutch Postcode lottery', role: 'Full-stack developer', from: '02/2019', to: '06/2021' },
          { company: 'Dutch Postcode lottery', role: 'Lead developer', from: '06/2021', to: 'present' }
        ];
      }

      education(): Array<Education> {
        return [
          { from: '03/2009', to: '03/2010', study: 'Employee MBO level 3' },
          { from: '03/2010', to: '03/2011', study: 'Application developer MBO level 4' },
          { from: '03/2013', to: '03/2017', study: 'Computer science: Software Engineering HBO' }
        ];
      }

      skills(): Array<string> {
        return ['JavaScript', 'Java', 'PHP', 'Go', 'CSS', 'Node.js', 'TypeScript', 'Vue.js', 'OOP', 'TDD'];
      }
    }
  `;

  const state = EditorState.create({
    doc: initialCode,
    extensions: [
      basicSetup,
      javascript(),
      dracula, // Theme
      EditorView.editable.of(false), // Read-only mode
    ],
  });

  if (isDesktop()) {
    Particles.init({
      selector: '.background',
      color: '#FFFFFF',
      connectParticles: true,
      retina_detect: true,
    });
  }

  // Create and mount the editor view
  new EditorView({
    state,
    parent: editorRef,
  });
});
